import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/404": [4],
		"/404/carnal": [5],
		"/dash": [~7,[2]],
		"/dash/admin/main": [~8,[2]],
		"/dash/admin/sentry": [9,[2]],
		"/dash/admin/shop": [~10,[2]],
		"/dash/admin/users": [11,[2]],
		"/dash/domains": [12,[2]],
		"/dash/login": [13,[2]],
		"/dash/members": [~14,[2]],
		"/dash/os": [~15,[2]],
		"/dash/os/[theme]": [~16,[2]],
		"/store": [~17],
		"/[username]": [~6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';